import './CardContent.css';

function CardContent({ children }) {
  return (
    <div className='p card-content'>
      { children }
    </div>
  )
}

export default CardContent;