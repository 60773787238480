import './App.css';
import Hero from './components/Hero/Hero';
import NavBar from './components/NavBar';
import ServerIcon from './components/icons/ServerIcon';
import PhotographIcon from './components/icons/PhotographIcon';
import Card from './components/Card';
import CardTitle from './components/CardTitle';
import CardContent from './components/CardContent';
import CardFooter, {FooterItem} from './components/CardFooter';
import MailIcon from './components/icons/MailIcon';
import LinkedInIcon from './components/icons/LinkedInIcon';
import Link from './components/Link/Link';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-P2DW5NZ'
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <div className="container mx-auto" name="">
      <NavBar />
      <a name="whoami"></a>
      <Hero> {/* Hero */}
        <div className='flex flex-col gap-10'>
          <div className='h4 font-nunito md:text-[76.29px] text-gray-200 flex flex-row gap-4'>
            <ServerIcon className='h-10 w-10 mt-2 md:h-20 md:w-20 md:mt-4' />
            Node.JS
          </div>
          <div className='h4 font-nunito md:text-[76.29px] text-gray-200 flex flex-row gap-4'>
            <PhotographIcon className='h-10 w-10 mt-2 md:h-20 md:w-20 md:mt-4' />
            React / Angular
          </div>
        </div>
        <div className='p-lg text-gray-300 my-10'>
          Hi, I’m Austin. I am a Javascript Developer with a passion for cyber security. Interested to see what I have done?
        </div>
        <div className='absolute h-full top-0 md:top-16 right-0 -z-10 opacity-20 lg:opacity-50 max-w-[900px] rounded-3xl'>
          <img src="/images/development.webp" alt="development laptop" className="h-full object-none md:rounded-3xl shadow-lg" loading="lazy"/>
        </div>
      </Hero>
      <div className='h6 ml-4 md:ml-0 underline mb-5'>
        Experience
        <a name="experience"></a>
      </div>
      <div className='flex flex-col px-4 md:px-0 lg:flex-row gap-10 mb-10'>
        <Card>
          <CardTitle>
            <img width="3rem" height="3rem"  className="w-12 h-12" src='https://forces.ca/assets/images/index/crest.png' alt='Forces logo' />
            <Link href="https://www.forces.ca" text="Canadian Armed Forces" className='p-lg link pt-1' tabIndex={5} />
          </CardTitle>
          <CardContent>
            During my service in the Army, I worked in small teams to accomplish strategic tasks. It is during my time in the Army that I honed my leadership skills in leading teams (5 - 40 people) with limited resources.
          </CardContent>
          <CardFooter>
            <FooterItem>
              Leadership
            </FooterItem>
            <FooterItem>
              Detail
            </FooterItem>
          </CardFooter>
        </Card>
        <Card>
          <CardTitle>
            <img width="3rem" height="3rem"  className="w-12 h-12" src='https://www.rogers.com/app/prod/assets/i/favicon.ico' alt='Rogers logo' />
            <Link href="https://www.rogerssportsandmedia.com/" text="Rogers Digital Media" className='p-lg link pt-1' tabIndex={6} />
          </CardTitle>
          <CardContent>
            During my time at Rogers Digital Media, I developed a web based automation tool that enabled nightly end tests on 100+ websites. This tool then provided the small QA team a Dashboard Application to better orientate their work.
          </CardContent>
          <CardFooter>
            <FooterItem>
              Angular
            </FooterItem>
            <FooterItem>
              Postgres
            </FooterItem>
          </CardFooter>
        </Card>
        <Card>
          <CardTitle>
            <img width="3rem" height="3rem"  className="w-12 h-12" src='/images/bti_logo.webp' alt='BTI logo' />
            <Link href="https://bti-systems.com/" text="BTI Systems" className='p-lg link pt-1' tabIndex={7} />
          </CardTitle>
          <CardContent>
            As a Junior Developer role, my time was focused on fixing bugs and implementing new features on an internal application used internationally to track and monitor test suites on micro-processing units.
          </CardContent>
          <CardFooter>
            <FooterItem>
              php
            </FooterItem>
            <FooterItem>
              jira
            </FooterItem>
          </CardFooter>
        </Card>
      </div>
      <div className='h6 underline mt-10 mb-5 ml-4 md:ml-0'>
        Projects
        <a name="projects"></a>
      </div>
      <div className='flex flex-row gap-10 mb-10 px-4 md:px-0'>
        <Card>
          <CardTitle>
            <img width="3rem" height="3rem"  className="w-12 h-12" src='/images/soundbot.png' alt='Forces logo' />
            <Link href="https://www.soundbot.ca" text="SoundBot" className='p-lg link pt-1' tabIndex={8} />
          </CardTitle>
          <CardContent>
            I'm working on bringing sound board functionality into a Discord Bot through the use of webhooks and a NextJS application. SoundBot uses Discord OAuth for authentication.
          </CardContent>
          <CardFooter>
            <FooterItem>
              NextJS
            </FooterItem>
            <FooterItem>
              OAuth
            </FooterItem>
          </CardFooter>
        </Card>
      </div>
      <div className='h6 w-36 underline mt-10 mb-5 ml-4 md:mx-auto md:pl-1'>
        Contact Me
        <a name="contactme"></a>
      </div>
      <div className='flex flex-row gap-5 w-full justify-center mb-10'>
        <a href="mailto:austin@kirbycodes.dev" className='bg-[#3500D3] h-16 min-w-[4rem] px-2 rounded-lg flex flex-row gap-4 justify-center pt-3 shadow-xl' tabIndex={9}>
          <MailIcon className='w-10 h-10' />
          <div className='h6 hidden md:block'>
            email
          </div>
        </a>
        <a href="https://www.linkedin.com/in/austinkirby/" className='bg-[#3500D3] h-16 min-w-[4rem] px-2 rounded-lg flex flex-row gap-4 justify-center pt-4 shadow-xl' tabIndex={10}>
          <LinkedInIcon className='w-8 h-8' />
          <div className='h6 hidden md:block'>
            LinkedIn
          </div>
        </a>
      </div>
    </div>
  );
}

export default App;
