import './Hero.css';

function Hero({children}) {

  return (
    <main className="hero">
      <div className="placeholder"></div>
      <div className="content">
        { children }
      </div>
    </main>
  )
}

export default Hero;