import './CardFooter.css';

export function FooterItem({children}) {

  return (
    <div className='flex flex-row gap-4 px-4 py-2 p-lg bg-[#3500D3] rounded-lg'>
      {children}
    </div>
  )
}

function CardFooter({children}) {

  return (
    <div className='flex flex-row justify-between h-12'>
      {children}
    </div>
  )
}

export default CardFooter;