import Link from '../Link/Link';
import './NavBar.css';

function NavBar() {

  return (
    <nav className='hidden sm:block px-4 sm:px-0 py-4 sticky top-0 bg-[#282828] z-20'>
      <div className="flex flex-row w-full">
        {/* NevBar */}
        <Link href="/" text="KirbyCodes" tabIndex={0}/>
        <div className='flex-grow'></div>
        <div className="hidden md:flex flex-row gap-10">
          <Link href="#whoami" text="Whoami" tabIndex={1} />
          <Link href="#experience" text="Experience" tabIndex={2} />
          <Link href="#projects" text="Projects" tabIndex={3} />
          <Link href="#contactme" text="Contact Me" tabIndex={4} />
        </div>
      </div>
    </nav>
  );
}

export default NavBar;