import './CardTitle.css';

function CardTitle({ children }) {
  return (
    <div className='p-lg card-title'>
      { children }
    </div>
  )
}

export default CardTitle;