import './Link.css';

function Link({href, text, className='p-lg link', tabIndex=0}) {

  return (
    <div className={className}>
      <a href={href} tabIndex={tabIndex}>
        {text}
      </a>
    </div>
  )
}

export default Link;